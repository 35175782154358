<template>
  <v-main>
    <v-container class="py-12">
      <v-row class="mb-12">
        <v-col>
          <div class="bl_titleImg">
            <h2 style="display: none;">デジタルお絵かきWORKSHOP</h2>
          </div>
          <p class="uq_centeredParagraph">タブレットやPCで気軽にお絵描きを楽しむミニ講座。<br />普段お絵描きを楽しんでいる方もこれからの方もお気軽にご参加ください。</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="el_btn__campaign">
              <h4 style="text-align:center;">ご応募ありがとうございました！<br />当選者にご連絡を順次お送りしています(10/16)</h4>
              <img src="/img/direct_img/artclass_img/campaign2023_9_2x.png" alt="Adobe CC 12ヶ月分が当たる! WORKSHOPスタートキャンペーン"
                style="border-radius:8px;" />
            </div>
          </template>
          <v-card class="bl_campaign">
            <v-card-title class="pt-8 bl_campaign_title">
              <img src="/img/direct_img/artclass_img/campaign2023_9_2x.png" alt="Adobe CC 12ヶ月分が当たる! WORKSHOPスタートキャンペーン"
                style="width:100%;" />
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">期間内にデジタルお絵描きWORKSHOPを受講の上ご応募いただいた方を対象に、抽選でAdobe Creative Cloud 12ヶ月サブスクリプション
                    を抽選で1名様にプレゼント！。外れた方の中から抽選でWORKSHOP受講割引クーポンが当たる！
                  </v-col>
                  <v-col cols="12">
                    <h3 class="my-4">キャンペーン概要</h3>
                    <dl>
                      <dt>応募受付締切</dt>
                      <dd>2023年10月15日 日曜日 23:59</dd>
                    </dl>
                    <dl>
                      <dt>賞品・当選人数</dt>
                      <dd>
                        Adobe Creative Cloud 12ヶ月サブスクリプション: 1名様<br />
                        デジタルお絵描きWORKSHOP 受講割引クーポン: 5名様
                      </dd>
                    </dl>
                    <dl>
                      <dt>応募方法</dt>
                      <dd>
                        <p>
                          1. 対象期間（2023年3月5日 水曜日 - 2023年9月30日 土曜日）の間にこのページに記載のワークショップを受講する。（受講一回お一人につき、１口応募いただけます。）
                        </p>
                        <p>
                          2. ワークショップで配布されたWEBアンケートに回答する。<br />
                          ※ご自身で制作した作品をアップロードしていただきます。※画像アップロードの際、google アカウントのログインが必要です。
                        </p>
                        <p>
                          3. 受付完了メールが届いたら応募完了です。
                        </p>
                      </dd>
                    </dl>
                    <dl>
                      <dt>抽選・結果発表</dt>
                      <dd>2023年10月末日までに抽選を行い、当選者に直接ご連絡いたします。抽選方法はサイコロ・乱数等による完全ランダムとします。</dd>
                    </dl>
                  </v-col>
                  <v-col>
                    <h3 class="my-4">応募規約</h3>
                    <p>キャンペーン概要と併せてご応募前にご確認ください。ご応募に際し、本キャンペーンサイト内の各事項を理解いただき、本規約に「同意」したものといたします。</p>
                    <dl>
                      <dt>規約</dt>
                      <dd>
                        <ul>
                          <li>本キャンペーンは、interemitが主催するキャンペーンであり、interemitがAdobe Community
                            Expertとして得た特典を本キャンペーンの賞品にさせていただくものです。アドビが共催ないしは協働するキャンペーンではありません。お問い合わせなどはinteremitまでお願いします。
                          </li>
                          <li>本キャンペーンは、事前に通知することなく、システムメンテナンスや不具合等により中止・中断・終了することがあります。</li>
                          <li>本キャンペーンへの応募には、googleアカウントが必要となります。</li>
                          <li>当選された方には、ご記入いただいた連絡先へinteremitアカウントよりメールでご連絡いたします。</li>
                          <li>当選者の発表は、当選者のみにご連絡させていただきます。</li>
                          <li>
                            ご応募いただいた内容はプライバシーポリシーに則り、当キャンペーンおよび、今後のお知らせに活用させていただきます。また、アップロードいただいた画像については下記の「アップロードいただく作品について」に基づき使用させていただきます。
                          </li>
                          <li>応募の有効無効、当落についてのお問い合わせには回答できません。</li>
                        </ul>

                        <h4 class="mt-8 mb-4">アップロードいただく作品について</h4>
                        <ul>
                          <li>ワークショップで作成した画像でも、他の機会に描いた作品でも構いません。テーマ・ジャンルも不問とします。</li>
                          <li>Adobe Frescoで作成したオリジナル画像に限ります。（他のアプリで明るさの調整など軽微な修正を行う分には問題ありません。）</li>
                          <li>受講者＝応募者ご本人の作品に限ります。（合作不可）</li>
                          <li>ご自身のSNS等で発表済みの作品でもご応募いただけますが、後述の通り応募時のニックネームと合わせて掲載する場合がございますので予めご承知おきください。</li>
                          <li>他のキャンペーンやコンテストに応募した作品はアップロード頂けません。</li>
                          <li>著作権等、他者の権利を侵害する作品はアップロード頂けません。</li>
                          <li>暴力的・反社会的・性的・他者に対する冒涜が連想される作品はアップロードいただけません。</li>
                          <li>応募時にアップロードいただきました作品については、ニックネーム、メールアドレスとともにデータベースに保存させていただきます。</li>
                          <li>当選発表時に作品を掲載するほか、当ワークショップの作例として、または販促物の一部として使用させていただきますことをご了承ください。</li>
                          <li>使用時は著作人格権に出来うる限り配慮いたします。</li>
                          <li>Adobe Community Expertの活動としてアドビおよびAdobe Community Expertのメンバーに報告する場合があります。（作品とニックネームのみ）</li>
                        </ul>

                      </dd>
                    </dl>
                    <dl>
                      <dt>応募資格</dt>
                      <dd>
                        <p>以下の条件に全て当てはまる方とします。</p>
                        <ul>
                          <li>対象期間にこのページに記載のワークショップを受講したご本人であること</li>
                          <li>規約に全て合意いただけること</li>
                          <li>当選後２週間以内に返信いただけること（ご返信がない場合、無効となり繰上げ当選者を抽選いたします）</li>
                          <li>（Adobe Creative Cloud
                            当選の場合）Adobeアカウント登録のため「お名前（漢字・アルファベット）」、「メールアドレス」、「国名」をお知らせいただたけること。（アカウントへの権利付与のためこの情報をAdobe社にお知らせします。）
                          </li>
                        </ul>
                        <small>
                          ※15歳未満の方は、保護者の同意が必要です。保護者の方に本規約に同意してもらってください。15歳未満の方からの応募は、すべて保護者の同意を得たものとみなします。<br />
                        </small>
                      </dd>
                    </dl>
                    <dl>
                      <dt>禁止事項</dt>
                      <dd>
                        <p>以下の事項に該当する場合、応募を無効とさせていただきます。</p>
                        <ul>
                          <li>受講申し込みをしたが当日受講しなかった場合</li>
                          <li>受講したご本人以外であるなど、不正な手段で応募した場合</li>
                          <li>運営を妨げたり、他の受講者に意図的に迷惑をかけるなどした場合</li>
                          <li>暴言を吐く、意図的に器物を破損するなど受講中に非協力的な態度であった場合</li>
                          <li>他の参加者よりも有利にするように等、特別扱いを要求した場合</li>
                          <li>その他、主催者が不適切だと判断した行為。</li>
                        </ul>
                        <p class="mt-6">以下の事項に該当する場合、キャンペーン期間後、または当選後でも賞品を無効とさせていただく場合があります。</p>
                        <ul>
                          <li>受賞後、2週間以内に返信を頂けなかった場合</li>
                          <li>不正が判明した場合</li>
                          <li>運営を妨げたり、他の受講者に意図的に迷惑をかけるなどした場合</li>
                          <li>暴言を吐く、意図的に遅延させる、器物を破損するなど受講中に非協力的な態度であった場合</li>
                        </ul>
                      </dd>
                    </dl>
                    <dl>
                      <dt>賞品について</dt>
                      <dd>
                        <ul>
                          <li>賞品の種別は抽選で決定されます。選ぶことはできません。</li>
                          <li>賞品の変更は不可となります。</li>
                          <li>Adobe Creative
                            Cloudに当選した権利は、ご本人のみ有効とし第三者に譲渡することはできません。また、賞品の転売は不可となります。発覚した場合にはAdobeに通報いたしますので、使用中に無効となる場合があります。
                          </li>
                          <li>受講クーポン券に当選した場合は譲渡が可能ですが、有償での転売はできません。発覚した場合には無効となります。</li>
                        </ul>
                      </dd>
                    </dl>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="green darken-1" text @click="dialog = false">
                閉じる
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row class="my-12">
        <v-col cols="12">
          <h3>開催ワークショップ一覧</h3>
        </v-col>
        <transition>
          <v-col cols=12 class="bl_wsWrapper"
            :class="{ 'bl_wsWrapper__isActive_1': isActive == 1, 'bl_wsWrapper__isActive_2': isActive == 2, 'bl_wsWrapper__isActive_3': isActive == 3 }">

            <class-card @active-detail="ActiveDetail(1)" :class="{ 'isActive': isActive == 1 }" :isActive="isActive"
              classNen="2023" classTsuki="全" classHi="3回" classYoubi="Sun" classTime="14:00-16:00" statusText="受付中"
              imgUrl="231028_ten_lines.png" reserveUrl="https://artstudioten-interemit-collaboration.peatix.com/"
              title="[3回講座]LINEスタンプを作ろう" location="ten" :tagList="['初心者向け', '3回講座', 'LINE']">
              <p>デジタルキャンバスで遊びながら「描く」を学ぼう！ 3回の講座でLINEスタンプを作ります。10/29（日曜日） 14:00-16:00 11/12（日曜日） 14:00-16:00 12/3（日曜日）
                14:00-16:00 の3回講座です。</p>
            </class-card>
            <class-card @active-detail="ActiveDetail(2)" :class="{ 'isActive': isActive == 2 }" :isActive="isActive"
              classNen="2023" classTsuki="7" classHi="8" classYoubi="Sat" classTime="14:00-16:00" statusText="開催済"
              imgUrl="circle_2306.png" reserveUrl="https://doc230708.peatix.com" title="デジタルお絵かきサークル会員募集！"
              location="nagamine" :tagList="['初心者向け', 'お絵描き', 'マイペース']">
              <p>
                デジタル環境でお絵かきを楽しんでいる・これから楽しみたい方のための、ゆる〜くお絵かきを楽しむサークル活動です。自由にそれぞれ絵を描きながら、アニメーションで動かしてみたり、印刷してみたり、イベントを開催していきたいと思っています。7/8は準備会としてサークルの説明や自己紹介を交えつつ、みんなでお絵描きする予定です。
              </p>
            </class-card>
            <class-card @active-detail="ActiveDetail(3)" :class="{ 'isActive': isActive == 3 }" :isActive="isActive"
              classNen="2023" classTsuki="6" classHi="30" classYoubi="Fri" classTime="11:00-13:00" statusText="開催済"
              imgUrl="230630_tsukuru_vector-2.png" reserveUrl="https://dow23630.peatix.com/"
              title="プロになる前に知っておきたいベクターブラシの使い方" location="tsukuru" :tagList="['初心者向け', 'ベクター', 'キャラクター']">
              <p>ベクターブラシと仲良くなろう！
                印刷でもwebでも大活躍、商業的にイラストを扱うなら知っておきたいベクター形式での制作を初心者さん向けに解説します。ラスター形式との違い、ベクターデータの用途、注意点等座学が多めです。Adobe
                Frescoのベクターブラシで実際にイラストを作成します。特にillustratorと合わせて使用する方におすすめです。</p>
            </class-card>
          </v-col>
        </transition>
        <v-col cols=12 class="d-flex justify-end">
          <router-link to="/class-past">過去のワークショップ</router-link>
        </v-col>
      </v-row>
      <v-row justify="center" class="pb-4">
        <v-col cols=12 sm="6">
          <h3 id="jukotebiki" class="pb-4">受講の手引き</h3>
          <ul>
            <li>
              <span>Adobe Frescoがインストールされたデバイスを、起動・描画できる状態でご持参ください。。</span><br />
              推奨環境は以下のページでご確認いただけます。<a href="https://helpx.adobe.com/jp/fresco/system-requirements.html"
                target="_blank">Adobe Fresco 必要システム構成(
                Adobe公式)</a>
            </li>
            <li>ペンはあるとより快適にお絵描きできますが必須ではありません。</li>
            <li>バッテリーを充電してからお持ちください。</li>
            <li>駐車場等アクセスについては各会場の公式情報をご確認ください。（公式情報のリンク先がない場合「詳細」でご案内しています。）</li>
          </ul>
        </v-col>
      </v-row>
      <v-row class="my-12">
        <v-col cols=12>
          <h3 class="pb-4">よくある質問</h3>
          <details>
            <summary>これまで絵を習ったことはありませんが描いてみたいです。受講可能ですか？<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              もちろんです、楽しんでいってください。このワークショップは絵を描く技術より、ツールの使い方を覚えて楽しく描くことを目的にしています。筆を動かして落書きしてみることから一緒に始めましょう。
            </div>
          </details>
          <details>
            <summary>当日急に行けなくなってしまいました。<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              Peatix等、申込されたイベントチケット販売サイトからキャンセルをお願いいたします。（詳しくはこのページ下部の特定商取引法に基づく表記「キャンセルについて」をご参照ください）<br />
              もし可能であれば、お知り合いに席をお譲りいただけましたらありがたく存じます。
            </div>
          </details>
          <details>
            <summary>当日会場でアプリをインストールしても構いませんか？<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              Adobe Frescoが動作することを受講前にあらかじめご確認の上でご参加ください。会場によってはwifiがない場合もございます。
            </div>
          </details>
          <details>
            <summary>デバイスがなくても受講できますか？<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              デバイスをご持参いただける方のみ受講可能です。
            </div>
          </details>
          <details>
            <summary>受講中にバッテリーが切れました。<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              電源タップをご用意しています。充電しながらお使いいただけます。数に限りがありますので、できる限りバッテリーを充電済の状態でお越しください。
            </div>
          </details>
          <details>
            <summary>少し古い機器ですが受講できますか？<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              Adobe Frescoが問題なく動作する環境であればご参加いただけます。Adobe Frescoの公式推奨環境に記載の機器かご確認ください。
            </div>
          </details>
          <details>
            <summary>子どもでも受講可能ですか？<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              資料はおおよそ中学生以上を想定しているので少し難しく感じるかもしれません。それでも構わないという場合は受講可能です。いらっしゃる場合は他の方の進度を妨げない範囲でですが、できる限りサポートします。また、デバイス自体が高価なため原則として小学生以下の場合は大人の方による送迎または付き添いをお願いいたします。（会場によっては付き添いの方が入れない場合があります。お問合せください）<br />
              キッズ向けワークショップも開催予定です。
            </div>
          </details>
          <details>
            <summary>子どもと一緒に親も受講できますか？<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              はい、参加される人数分のデバイスをお持ちください。
            </div>
          </details>
          <details>
            <summary>タブレットを買ったばかりで操作がわかりません。指導してくれますか？<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              電源の入れ方、アプリの開き方や閉じ方、タップ、ドラッグなどの基本操作ができている方を対象とさせていただきます。
            </div>
          </details>
          <details>
            <summary>Adobe Frescoの有料版じゃないと受講できませんか？<span class="el_btn__summaryOpen"></span></summary>
            <div class="bl_detailsInner">
              Adobe Frescoの無料版で十分お楽しみいただけます。気になる場合は有料版の機能も少しご紹介しますので検討材料にどうぞ。
            </div>
          </details>
        </v-col>
      </v-row>
      <v-row class="my-12" justify="center">
        <v-col cols=12 sm=6>
          <div class="d-flex" style="flex-direction:column;">
            <h4 class="mb-4">講師： 麻生 江見</h4>
            <img src="/img/common/Adobe_Community_Expert_badge.png" class="mb-4" style="height:auto; width: 200px;" />
          </div>
          <p>
            小学生の頃からデジタルでお絵描きを楽しんできました。大学でグラフィックデザインを学び、インハウスデザイナーとして店舗の広告宣伝を担当したのち、独立。現在はフリーランスでイラスト、webシステムのUI、印刷物など色々な媒体でデザインさせていただいています。ゆるっとした親しみやすいイラストが好きですが、調べながらなんでも描いてます。<br />
            上記の単発ワークショップのほか、阿見町中央公民館で講座を担当しています。<br />
            2023年からAdobe Community Expertとして、主に初心者さん向けにAdobe Fresco等デザイン・グラフィックツールの使い方サポートをしています。
          </p>
        </v-col>
      </v-row>
      <v-row class="my-12">
        <v-col cols=12 lg="6">
          <h4 class="pb-4">特定商取引法に基づく表記</h4>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">販売業者名</dt>
            <dd class="bl_tokutei_body">interemit</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">販売責任者</dt>
            <dd class="bl_tokutei_body">麻生江見</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">所在地</dt>
            <dd class="bl_tokutei_body">所在地については販売契約時、またはご請求があった場合「info[at]interemit.com」にてご請求をいただければ、遅滞なく開示いたします。</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">電話番号</dt>
            <dd class="bl_tokutei_body">電話番号については販売契約時、またはご請求があった場合「info[at]interemit.com」にてご請求をいただければ、遅滞なく開示いたします。</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">メールアドレス</dt>
            <dd class="bl_tokutei_body">info[at]interemit.com</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">支払方法</dt>
            <dd class="bl_tokutei_body">Peatix等イベントチケット販売サイトごとの支払方法をご確認ください。
              現地開催のイベントの場合、ご希望があれば当日に限り現金でのお支払いも可能です。</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">お申込み有効期限</dt>
            <dd class="bl_tokutei_body">各イベントごとに記載</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">商品以外の必要料金</dt>
            <dd class="bl_tokutei_body">各イベントごとに必要があれば記載。</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">キャンセルについて</dt>
            <dd class="bl_tokutei_body">キャンセルをご希望の場合はPeatix等各イベントチケット販売サイトよりキャンセルを申請してください。「キャンセルの場合のご返金について」を併せてご参照ください。
            </dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">表現、及び商品に関する注意書き</dt>
            <dd class="bl_tokutei_body">講座内容および広告での表現やその再現性には個人差があり、可能な限り依頼者の利益に寄与するよう努めますが必ずしも効果を保証したものではありません。</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">受講に必要なシステム環境</dt>
            <dd class="bl_tokutei_body">受講の際は、講座に適したＰＣやタブレット端末を受講者ご自身でご準備願います。詳しくはこのページ内の「受講の手引き」をご確認ください。</dd>
          </dl>
          <dl class="bl_tokutei">
            <dt class="bl_tokutei_title">主催者都合のサービス提供中止について</dt>
            <dd class="bl_tokutei_body">主催者の都合により講座の開催が中止になる場合は、振替日にて実施または全額返金いたします。</dd>
          </dl>
        </v-col>
        <v-col cols=12 lg="6" class="px-8">
          <h4 class="mb-4">キャンセルの場合のご返金について</h4>
          <p>必ずお読みいただき、ご同意いただける方のみ受講をお申込ください。</p>
          <p>
            キャンセルの際はPeatix等イベントチケット販売サイトからキャンセル申請をお願いします。返金の際はPeatix等イベントチケット販売サイトのキャンセル規約に従い、お支払い方法により手数料を差し引かせていただきます。ご了承くださいませ。
          </p>
          <p>なお、災害などのやむをえない事情によりイベントの開催を中止する場合には、後日銀行振込による全額返金（この場合、振込料金はinteremitが負担いたします）または他の開催日に振り替えとさせていただきます。</p>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ClassCard from '../components/molecules/ClassCard.vue'

export default {
  components: { ClassCard },
  name: 'ClassIndex',
  data() {
    return {
      isActive: 0,
      dialog: false, //キャンペーンのモーダル開閉
    }
  },
  methods: {
    ActiveDetail(value) {
      if (this.isActive == value) {
        this.isActive = 0
      } else {
        this.isActive = value;
      }
    },
  }
};
</script>
<style scoped>
.bl_titleImg {
  display: flex;
  margin: 32px auto 64px;
  width: 50vw;
  min-width: 300px;
  max-width: 500px;
  aspect-ratio: 7 / 5;
  background: linear-gradient(135deg, #eea2a2 0%, #eea2a2 20%, #bbc1bf 30%, #57c6e1 60%, #b49fda 85%, #7ac5d8 100%) fixed;
  background-size: 200% 200%;
  -webkit-mask-image: url(/img/common/digitalOekakiWorkshop_00_2x.png);
  mask-image: url(/img/common/digitalOekakiWorkshop_00_2x.png);
  mask-size: 100% auto;
  mask-repeat: no-repeat;
  animation-name: gradation;
  animation-duration: 10.0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes gradation {
  0% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.uq_centeredParagraph {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  padding: 8px 32px;
}

/* キャンペーン */
.el_btn__campaign {
  display: flex;
  flex-direction: column;
  margin: 36px auto;
  width: 40vw;
  min-width: 300px;
  max-width: 450px;
  aspect-ratio: 5/2;
}

.el_btn__campaign img {
  max-width: 100%;
}

.bl_campaign dt {
  width: 8em;
}

.bl_campaign li {
  line-height: 1.2;
}

.theme--light.v-card>.v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.9);
}

.bl_wsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 32px;
}

.bl_wsWrapper.bl_wsWrapper__isActive_1 {
  grid-template-columns: 3fr 1fr 1fr;
}

.bl_wsWrapper.bl_wsWrapper__isActive_2 {
  grid-template-columns: 1fr 3fr 1fr;
}

.bl_wsWrapper.bl_wsWrapper__isActive_3 {
  grid-template-columns: 1fr 1fr 3fr;
}

.bl_wsWrapper>>>.bl_card.bl_card__ws.isActive {
  box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.25), 0px 5px 5px 2px rgba(0, 0, 0, 0.15), 0px 4px 13px 2px rgba(0, 0, 0, 0.13);
}

dl.bl_tokutei {
  display: grid;
  grid-template-columns: minmax(8em, 1fr) 3fr;
  column-gap: 24px;
  padding: 16px 2px;
  border-top: 1px solid #ccc;
  font-size: 12px;
}

@media screen and (max-width: 1280px) {

  .bl_wsWrapper,
  .bl_wsWrapper.bl_wsWrapper__isActive_1,
  .bl_wsWrapper.bl_wsWrapper__isActive_2,
  .bl_wsWrapper.bl_wsWrapper__isActive_3 {
    grid-template-columns: 1fr;
  }

  .bl_wsWrapper>>>.bl_card.bl_card__ws.isActive {
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 700px) {

  .bl_wsWrapper,
  .bl_wsWrapper.bl_wsWrapper__isActive_1,
  .bl_wsWrapper.bl_wsWrapper__isActive_2,
  .bl_wsWrapper.bl_wsWrapper__isActive_3 {
    grid-template-columns: 1fr;
  }
}
</style>
